


















import { Component, Prop } from 'vue-property-decorator';
import { dispatchUpdateRequisition } from '@/store/crud/actions';
import PatientData from '@/components/PatientData.vue';
import RequisitionPanels from '@/components/RequisitionPanels.vue';
import { readUserProfile } from '@/store/main/getters';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IConfirmRequisition, IRequisition } from '@/interfaces/requisitions';
import { IVisit } from '@/interfaces/visits';
import { IPackage } from '@/interfaces/packages';

@Component({ components: { PatientData, RequisitionPanels } })
export default class LabRequisitionItem extends AppComponent {
  @Prop({ type: Object }) public visit: IRequisition | undefined;
  @Prop({ type: Boolean }) public showApprove;


  get displayApproveButton() {
    return this.showApprove;
  }
  get requisition() {
    return this.visit;
  }

  get userProfile() {
      return readUserProfile(this.$store);
  }

  get requisitionVisits() {
    const visits  = this.requisition && this.requisition.visits && this.requisition.visits.length > 0
      ? this.requisition.visits : [];
    return visits as IVisit[];
  }

  /**
   * Gets the panels from all the visits
   */
  get panels() {
    const panels: any = [];

    this.requisitionVisits.forEach((visit: IVisit) => {
      if (visit.packages && visit.packages.length > 0) {
        (visit.packages as IPackage[]).forEach((packageItem: IPackage) => {
          if (packageItem.panels && packageItem.panels.length > 0) {
            panels.push(...packageItem.panels);
          }
        });
      }
    });

    return panels;
  }

  /*
    It approves the current requisition and re-configure the screen
    by calling the initial configureScreen, this will clear all the
    screen data and re-draw
  */
  public async confirm() {
    if (!this.userProfile || !this.userProfile.id || !this.userProfile.isPhysician
      || !this.requisition || !this.requisition.id) {
      return;
    }
    const confirmRequisition: IConfirmRequisition = {
      confirmation: true,
      referringPhysicianId: this.userProfile.id,
    };

    const result = await dispatchUpdateRequisition(this.$store, {
      requisitionId: this.requisition.id,
      requisition: confirmRequisition,
    });

    if (result) {
      this.toast('requisition approved', false);
      this.$emit('requisition-approved', this.visit);
    } else {
      this.toast('error while approving requisition', true);
    }

  }
}
