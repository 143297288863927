
























































































































































import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';
import { readUserProfile } from '@/store/main/getters';

import LabRequisition from '@/components/LabRequisitionItem.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { readRequisitions } from '@/store/crud/getters';
import { IRequisition } from '@/interfaces/requisitions';
import { dispatchGetRequisitionsByState } from '@/store/crud/actions';

@Component({ components: { LabRequisition } })
export default class UserProfile extends AppComponent {
  // public userProfile: IUserProfile | null;
  public userRequisitions: IRequisition[] = [];

  /*
    it reads the user profile on mounting
    With the user profile we will configure the screen
    and separate logic if the user is physician or Admin
  */
  public async mounted() {
    // we get physician information here
    if (this.userProfile && this.userProfile.isPhysician) {
      this.fetchPhysicianData();
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public goToEdit() {
    this.$router.push('/main/profile/edit');
  }

  public goToPassword() {
    this.$router.push('/main/profile/password');
  }

  /**
   * It returns the built user name
   * based on if it is nurse, or physician
   */
  public getUserName() {
    let name = '';
    let addSufix = true;
    // build the name based on if it has a first name and last name or not
    if (this.userProfile && this.userProfile.fname && this.userProfile.lname) {
      name = `${this.userProfile.fname} ${this.userProfile.lname}`;
    } else if (this.userProfile && this.userProfile.fname && !this.userProfile.lname) {
      name = this.userProfile.fname;
    } else if (this.userProfile && !this.userProfile.fname && this.userProfile.lname) {
      name = this.userProfile.lname;
    } else {
      name = this.userProfile?.email || '';
      addSufix = false;
    }

    // add the suffix NP if it is a nurse or MD if it is a physician
    if (this.userProfile && this.userProfile.isPhysician && addSufix) {
      name += ', MD';
    } else if (this.userProfile && !this.userProfile.isPhysician && this.userProfile.isNursePracticioner && addSufix) {
      name += ', NP';
    }

    return name;
  }

  public getLicenceStates() {
    if (this.userProfile && this.userProfile.licenseState && this.userProfile.licenseState.length > 0) {
      return this.userProfile.licenseState.map((state) => {
        return state.licenseState;
      }).join(', ');
    }
    return '-----';
  }

  /*
    This function will update the screen by removing the
    approved requisition while we fetch data again.
    When we remove the requisition, the screen will be re-drawn
    meanwhile we will re-fetch data to have an accure information
  */
  public async updateScreen(requisition) {
    const elementIndex = this.userRequisitions.indexOf(requisition);
    this.userRequisitions.splice(elementIndex, 1);
    await this.fetchPhysicianData();
  }

  private async fetchPhysicianData() {
    this.setAppLoading(true);
    await dispatchGetRequisitionsByState(store);
    this.userRequisitions = readRequisitions(store);
    this.setAppLoading(false);
  }
}
