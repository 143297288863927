





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RequisitionPanels extends Vue {
  @Prop({ type: Array }) public panels: any[];

  public isArray(item) {
    return Array.isArray(item);
  }
}
